import React from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import ReportHeader from '../components/report/ReportHeader';
import ReportMenu from '../components/report/ReportMenu';
import { PATH } from '../routes/Routes';
import CRSTO03ReportView from './reports/CRSTO03ReportView';
import CRSTO06ReportView from './reports/CRSTO06ReportView';
import CRSTO07ReportView from './reports/CRSTO07ReportView';
import ExpenseMonthlyReportView from './reports/ExpenseMonthlyReportView';
import LabReceptionSummaryReportView from './reports/LabReceptionSummaryReportView';
import MaterialReportView from './reports/MaterialReportView';
import PondStatisticReportView from './reports/PondStatisticReportView';
import ACGroupByCreditReportView from './reports/purchase/ACGroupByCreditReportView';
import DeliveryOrderReportView from './reports/purchase/DeliveryOrderReportView';
import PettyCashReportView from './reports/purchase/PettyCashReportView';
import PurchaseByPOReportView from './reports/purchase/PurchaseByPOReportView';
import SellFieldLatexReportView from './reports/SellFieldLatexReportView';
import SparePartReportView from './reports/SparePartReportView';
import SparePartReportRemainView from './reports/SparePartReportRemainView';
import SummaryFieldLatexReportView from './reports/SummaryFieldLatexReportView';
import LotNoReportView from './reports/tank/LotNoReportView';
import SellConcentLatexReportView from './reports/tank/SellConcentLatexReportView';
import STWeeklyReportView from './reports/tank/STWeeklyReportView';
import STWeeklyStatisticReportView from './reports/tank/STWeeklyStatisticReportView';
import SummaryConcentLatexReportView from './reports/tank/SummaryConcentLatexReportView';
import TestFieldLatexReportView from './reports/TestFieldLatexReportView';

export const REPORT_PATH = {
  REPORT_TEST_FIELD_LATEX: 'report-test-field-latex',
  REPORT_LAB_LOG_SHEET: 'report-lab-log-sheet',
  REPORT_STAT_POND: 'report-stat-pond',
  REPORT_SUMMARY_FIELD_LATEX: 'report-summary-field-latex',
  REPORT_SELL_FIELD_LATEX: 'report-sell-field-latex',
  REPORT_LOT_NO: 'report-lot-no',
  REPORT_ST_WEEKLY: 'report-st-weekly',
  REPORT_ST_WEEKLY_STAT: 'report-st-weekly-stat',
  REPORT_SELL_CONCENT_LATEX: 'report-sell-concent-latex',
  REPORT_SUMMARY_CONCENT_LATEX: 'report-summary-concent-latex',
  REPORT_CR_STO_06: 'report-cr-sto-06',
  REPORT_CR_STO_03: 'report-cr-sto-03',
  REPORT_EXPENSE_REPORT: 'report-expense-report',
  REPORT_PURCHASE_BY_PO: 'report-purchase-by-po',
  REPORT_PETTY_CASH: 'report-petty-cash',
  REPORT_AC_GROUP_CREDIT: 'report-ac-group-credit',
  REPORT_CR_STO_07: 'report-cr-sto-07',
  REPORT_SPARE_PART: 'report-spare-part',
  REPORT_SPARE_PART_REMAIN: 'report-remain-spare-part',
  REPORT_DELIVERY_ORDER: 'report-delivery-order',
  REPORT_MATERIAL: 'report-material',
}


export default function ReportView() {

  const reportMenus = [
    {
      icon: 'chevron right',
      text: 'น้ำยางสด',
      color: 'blue',
      subMenus: [
        { text: '1. บันทึกผลการทดสอบน้ำยางสด เข้าโรงงาน', path: REPORT_PATH.REPORT_TEST_FIELD_LATEX },
        { text: '2. บ่อพักน้ำยาง Lab Reception Log Sheet', path: REPORT_PATH.REPORT_LAB_LOG_SHEET },
        { text: '3. บ่อพักน้ำยาง (เชิงสถิติ)', path: REPORT_PATH.REPORT_STAT_POND },
        { text: '4. สรุปน้ำยางสด', path: REPORT_PATH.REPORT_SUMMARY_FIELD_LATEX },
        { text: '5. การขายน้ำยางสด', path: REPORT_PATH.REPORT_SELL_FIELD_LATEX },
      ]
    },
    {
      icon: 'chevron right',
      text: 'น้ำยางข้น',
      color: 'teal',
      subMenus: [
        {  text: '1. ประวัติ Storage Tank ต่อ Lot No.', path: REPORT_PATH.REPORT_LOT_NO },
        {  text: '2. ST Weekly Report', path: REPORT_PATH.REPORT_ST_WEEKLY },
        {  text: '3. ST Weekly Report (เชิงสถิติ)', path: REPORT_PATH.REPORT_ST_WEEKLY_STAT },
        {  text: '4. การขายน้ำยางข้น', path: REPORT_PATH.REPORT_SELL_CONCENT_LATEX },
        {  text: '5. สรุปน้ำยางข้น (CR-STO-10)', path: REPORT_PATH.REPORT_SUMMARY_CONCENT_LATEX },
      ]
    },
    {
      icon: 'chevron right',
      text: 'คลังสินค้า',
      color: 'green',
      subMenus: [
        { text: '1. การเบิกวัตถุดิบ/วัสดุ (CR-STO-06)', path: REPORT_PATH.REPORT_CR_STO_06 },
        { text: '2. ใบขอซื้อ/ขอซ่อม (CR-STO-03)', path: REPORT_PATH.REPORT_CR_STO_03 },
        { text: '3. รายงานค่าใช้จ่ายประจำเดือน', path: REPORT_PATH.REPORT_EXPENSE_REPORT },
        { text: '4. รายงานการสั่งซื้อ', path: REPORT_PATH.REPORT_PURCHASE_BY_PO },
        { text: '5. รายงานเงินสดย่อย', path: REPORT_PATH.REPORT_PETTY_CASH },
        { text: '6. รายงานสรุปค่าใช้จ่าย', path: REPORT_PATH.REPORT_AC_GROUP_CREDIT },
        { text: '7.รายงานใบตรวจรับสินค้า', path: REPORT_PATH.REPORT_DELIVERY_ORDER },
        { text: '8.รายงานสารเคมี-บรรจุภัณฑ์ (Stock Card)', path: REPORT_PATH.REPORT_CR_STO_07 },
        { text: '9.รายงาน Spare part', path: REPORT_PATH.REPORT_SPARE_PART },
        { text: '10.รายงาน Material', path: REPORT_PATH.REPORT_MATERIAL },
        { text: '11.รายงาน Spare part คงเหลือ', path: REPORT_PATH.REPORT_SPARE_PART_REMAIN },
      ]
    },
  ]

  const reportMenusHeaderMap = reportMenus.reduce((obj, reportMenu) => (
    {...obj, ...reportMenu.subMenus.reduce((o, subMenu) => ({...o, [subMenu.path]: { subMenu: subMenu.text, menu: reportMenu.text } }), {})}
  ), {})

  const params = useParams();
  const [menuText, setMenuText] = React.useState(null);
  const [subMenuText, setSubMenuText] = React.useState(null);

  const onSelectMenu = (menu) => {
    setMenuText(reportMenusHeaderMap[menu] ? reportMenusHeaderMap[menu].menu : '')
    setSubMenuText(reportMenusHeaderMap[menu] ? reportMenusHeaderMap[menu].subMenu: '')
  }

  const clearMenu = () => {
    setMenuText(null);
    setSubMenuText(null);
  }
  
  React.useEffect(() => {
    if (params.menu) {
      onSelectMenu(params.menu)
    } else {
      clearMenu();
    }
  }, [params])

  return (
    <div>
      <ReportHeader headerText={subMenuText} subHeaderText={menuText}/>

      <Switch>
        <Route exact path={PATH.REPORT} component={() => <ReportMenu menus={reportMenus}/> }/>

        {/* น้ำยางสด */}
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_TEST_FIELD_LATEX} component={TestFieldLatexReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_LAB_LOG_SHEET} component={LabReceptionSummaryReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_STAT_POND} component={PondStatisticReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_SUMMARY_FIELD_LATEX} component={SummaryFieldLatexReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_SELL_FIELD_LATEX} component={SellFieldLatexReportView} />

        {/* น้ำยางข้น */}
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_LOT_NO} component={LotNoReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_ST_WEEKLY} component={STWeeklyReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_ST_WEEKLY_STAT} component={STWeeklyStatisticReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_SELL_CONCENT_LATEX} component={SellConcentLatexReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_SUMMARY_CONCENT_LATEX} component={SummaryConcentLatexReportView} />
        
        {/* คลังสินค้า */}
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_CR_STO_06} component={CRSTO06ReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_CR_STO_03} component={CRSTO03ReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_EXPENSE_REPORT} component={ExpenseMonthlyReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_PURCHASE_BY_PO} component={PurchaseByPOReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_PETTY_CASH} component={PettyCashReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_AC_GROUP_CREDIT} component={ACGroupByCreditReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_DELIVERY_ORDER} component={DeliveryOrderReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_CR_STO_07} component={CRSTO07ReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_SPARE_PART} component={SparePartReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_MATERIAL} component={MaterialReportView} />
        <Route exact path={PATH.REPORT + REPORT_PATH.REPORT_SPARE_PART_REMAIN} component={SparePartReportRemainView} />

        <Route path='*'><Redirect to={PATH.REPORT}/></Route>
      </Switch>
    </div>
  )
}
