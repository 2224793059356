import React from 'react';
import { Button, Container, Dimmer, Form, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { URL_REPORT, URL_STOCK } from '../../constances/urls';
import { MonthInput, YearInput } from 'semantic-ui-calendar-react';
import { MONTH_STRING_TH } from '../../constances';
import moment from "moment";
import TSReactTable from '../../frameworks/TSReactTable';
import { getColumnsList } from '../../utils/Util';
import { GET } from '../../frameworks/HttpClient';
import { formatComma } from '../../frameworks/Util';


export default function SparePartReportRemainView() {
  const [dateFilter, setDateFilter] = React.useState(moment());
  const [data, setData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(false);

  const columns = React.useMemo(() => [
    ...getColumnsList([
      {
        Header: "No.",
        accessor: "idx"
      },
      { 
        Header: "Name", 
        accessor: "product_name"
      },
      { 
        Header: "Unit", 
        accessor: "remain_amount", 
        style: { textAlign: "right"},
      },
      {
        Header: "@",
        accessor: "remain_price_per_unit",
        style: { textAlign: "right" },
        Cell: ({value}) => value ? formatComma(value, true) : null 
      },
      {
        Header: "Amount",
        accessor: "remain_total_price",
        style: { textAlign: "right" },
        Cell: ({value}) => value ? formatComma(value, true) : null 
      },
    ])
  ])

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_REPORT.SPARE_PART_REMAIN_REPORT, { date: dateFilter })
      setData(response.data)
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleExport = (exportType) => {
    window.open(`${URL_REPORT.SPARE_PART_REMAIN_REPORT_EXPORT}${exportType}/?date=${dateFilter}`);
  }

  return (
    <Container fluid style={{marginTop: 24}}>

      { errorMessages && <Message error>{errorMessages}</Message> }

      <Form>
        <Form.Group inline>
          <label>{"วันที่"}</label>
          <Form.Field
            control={SemanticDatepicker}
            locale="en-US"
            showToday={true}
            format="DD/MM/YY"
            placeholder={"เลือกวันที่..."}
            onChange={(_, data) => setDateFilter(moment(data.value).format("YYYY-MM-DD"))} />

          <Button primary onClick={() => fetchData()}>ค้นหา</Button>
        </Form.Group>
      </Form>

      <Segment basic style={{padding: 0}}>
				<Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
				
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => handleExport("pdf")}
					>
						<Icon name={"file pdf"}/>
						PDF
				</Button>

				<Button 
					style={{marginTop: 8}}
					color={"green"}
					floated={"right"}
					size={"mini"}
					onClick={() => handleExport("excel")}
					>
						<Icon name={"file excel"}/>
						Excel
				</Button>
			</Segment>
    </Container>
  );
}