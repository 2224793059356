import React from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router";
import { Container, Dimmer, Form, Loader, Message, Button, Icon } from "semantic-ui-react";
import TSReactTable from "../../../frameworks/TSReactTable";
import ImageButton from "../../common/ImageButton";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../../utils/Util";
import { PATH } from "../../../routes/Routes";
import edit from "../../../assets/img_edit.png";
import { GET } from "../../../frameworks/HttpClient";
import { QUOTATION_TPYE, URL_PURCHASE } from "../../../constances";
import { dateToString, formatComma } from "../../../frameworks/Util";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

export default function PurchaseApprovalSegment(props) {
	const history = useHistory();
	const [dateFilter, setDateFilter] = React.useState(moment().format("YYYY-MM-DD"));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{
				Header: "Action",
				headerStyle: TABLE_HEADER_STYLE,
				width: 60,
				show: props.canEdit,
				Cell: (row) => {
					return (
						<div>
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={() => handleEdit(row.original)}/>
						</div>
					)
				}
			},
			{ Header: "วันที่ขอซื้อ", 
				accessor: "created", 
				width: 100,
				Cell: ({ value }) => <p>{dateToString(value)}</p> 
			},
			{ 
				Header: "สินค้า", 
				accessor: "product.name",
				minWidth: 200, 
				Cell: ({original, value}) => 
					(<p>{original.ref_product ? `${value} (${original.ref_product.name})` : value}</p>)
			},
			{ 
				Header: "ผู้ขาย", 
				accessor: "selected_quotation.type", 
				width: 200,
				Cell: ({ original, value }) => 
					value === "quotation" ? original.selected_quotation.supplier.name :  QUOTATION_TPYE[value] 
			},
			{ Header: "ผู้ขอซื้อ", accessor: "created_name", width: 100},
			{ Header: "ผู้อนุมัติ", accessor: "approved_by", width: 100, show: props.showApproval}, 
			{ Header: "สถานะ", accessor: "state_name", width: 120 }, 
			{ Header: "จำนวน", accessor: "amount", width: 80, Cell: ({ value }) => formatComma(value) },
			{ Header: "หน่วย", accessor: "unit", width: 80 },
			{ Header: "ราคาต่อหน่วย", accessor: "selected_quotation.unit_price", width: 100, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคารวม", accessor: "selected_quotation.total_price", width: 100, Cell: ({ value }) => formatComma(value) },
			{ 
				Header: "ราคาจริง", 
				accessor: "selected_quotation.real_price", 
				width: 100, 
				Cell: ({ value }) => value > 0 ? formatComma(value) : "-" 
			},
			{ 
				Header: "รหัสบัญชี", 
				accessor: "purchase_record", 
				width: 120,
				Cell: ({value}) => value ? value.account_code_detail.code : "-"
			},
			{ 
				Header: "รายการบัญชี", 
				accessor: "purchase_record", 
				width: 200,
				Cell: ({value}) => value ? value.account_code_detail.name : "-"
			},
		]),
	]);

	const fetchData = async () => {
		setLoading(true);
		setErrorMessages(null)
		let params = props.queryParams;
		if (props.showFilterDate)
			params.created_date = dateFilter

		try {
			const response = await GET(URL_PURCHASE.PURCHASE_REQUEST, params);
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const handleEdit = async (item) => {
		// Goto edit screen
		history.push(`${PATH.PURCHASE_DETAIL}${item.id}/`)
	}

	const handleExportExcel = async() => {
		let params = props.queryParams;
		if (props.showFilterDate)
			params.created_date = dateFilter

		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_REQUEST}export_xlsx/`, params);
			console.log(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	React.useEffect(() => {
		if (dateFilter) {
			if (!moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
				if (!dateFilterError)
					setDateFilterError(true);
				return;
			}
			
			if (dateFilterError)
				setDateFilterError(false);
			fetchData();
		}
	}, [dateFilter])

	return (
		<Container fluid style={props.style}>
			
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			{props.showFilterDate && (
				<Form>
					<Form.Group inline>
						<label>{"วันที่ขอซื้อ"}</label>
						<Form.Field
							control={DateInput}
							closable={true}
							dateFormat="YYYY-MM-DD"
							maxDate={moment()}
							placeholder={"เลือก..."}
							error={dateFilterError}
							value={dateFilter}
							onChange={(_, data) => setDateFilter(data.value)}/>
					</Form.Group>
				</Form>
			)}

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/>

			<Button
				style={{ marginTop: 20 }}
				color={"green"}
				floated={"right"}
				size={"mini"}
				onClick={handleExportExcel}
			>
				<Icon name={"file excel"} />
				Excel
			</Button>
		</Container>
	)
}

PurchaseApprovalSegment.defaultProps = {
	style: {},
	queryParams: {},
	canEdit: true,
	showFilterDate: false,
	showApproval: false,
}

PurchaseApprovalSegment.propTypes = {
	style: propTypes.object,
	queryParams: propTypes.object,
	canEdit: propTypes.bool,
	showFilterDate: propTypes.bool,
	showApproval: propTypes.bool,
}